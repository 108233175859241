<template>
  <CRow class="justify-content-center">
    <CCol class="c-col" col="11" md="12" lg="11">
      <CCard class="ccard shadow-none" color="second-card-bg">
        <CCardBody class="ccard-body">
          <CForm>
            <div class="logo-wrap">
              <a :href="homepage_url">
                <logo-wrap :auth="true" />
              </a>
            </div>
            <h1 class="title">Оформить подписку</h1>
            <p class="subtitle text-muted">Доступ к данному веб-порталу возможен только при оплате подписки</p>

            <CRow class="subactions">
              <CCol col="12" xl="6">
                <CButton block color="dark" class="cbtn shadow-none" @click="$router.go(-1)">Назад</CButton>
              </CCol>
              <CCol col="12" xl="6">
                <CButton block color="primary" class="cbtn shadow-none">Оформить</CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import LogoWrap from "@/components/LogoWrap";
// import store from "@/store";

export default {
  name: "Subscribe",
  components: {
    LogoWrap
  },
  computed: {
    homepage_url() {
      let hp = process.env.VUE_APP_HOMEPAGE_URL
      if(hp && hp.slice(-1) !== '/'){
        hp += '/'
      }
      if(this.locale === 'ru'){
        hp += 'ru/';
      }
      return hp;
    },
  }
  // beforeRouteEnter (to, from, next) {
  //   const accept = store.getters['user/isLogged']
  //   next(accept)
  // }
}
</script>

<style lang="scss" scoped>
.col-xs {
  @include media-breakpoint-down(xs) {
    flex: 0 0 16.875rem;
    max-width: 16.875rem;
  }
}
.ccard {
  margin: 0;

  .logo-wrap {
    text-align: center;
  }
  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
  .subactions {
    flex-direction: column-reverse;

    & > :last-child {
      margin-bottom: 0.625rem;
    }
  }
  .cbtn {
    height: 2.75rem;
  }

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1.875rem 0.9375rem 3.75rem;
    }
    .logo-wrap {
      margin-bottom: 0.375rem;
    }
    .title {
      font-size: 1.5rem;
    }
    .subtitle {
      margin-bottom: 1.875rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 3rem;
    }
    .logo-wrap {
      margin-bottom: 1.5rem;
    }
    .subtitle {
      margin-bottom: 2.5rem;
    }
  }

  @include media-breakpoint-up(md) {
    .ccard-body {
      padding: 3rem 5.125rem 5rem;
    }
  }

  @include media-breakpoint-up(xl) {
    .ccard-body {
      padding: 3.75rem 8.625rem 5.5rem;
    }
    .logo-wrap {
      text-align: start;
    }
    .title {
      text-align: start;
    }
    .subtitle {
      text-align: start;
      margin-bottom: 3.125rem;
    }
    .subactions {
      flex-direction: row;

      & > :last-child {
        margin-bottom: 0;
      }
    }
  }
}

.c-col {
  padding: 1rem 0;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 0;
  }
}
</style>
