var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    { staticClass: "justify-content-center" },
    [
      _c(
        "CCol",
        { staticClass: "c-col", attrs: { col: "11", md: "12", lg: "11" } },
        [
          _c(
            "CCard",
            {
              staticClass: "ccard shadow-none",
              attrs: { color: "second-card-bg" },
            },
            [
              _c(
                "CCardBody",
                { staticClass: "ccard-body" },
                [
                  _c(
                    "CForm",
                    [
                      _c("div", { staticClass: "logo-wrap" }, [
                        _c(
                          "a",
                          { attrs: { href: _vm.homepage_url } },
                          [_c("logo-wrap", { attrs: { auth: true } })],
                          1
                        ),
                      ]),
                      _c("h1", { staticClass: "title" }, [
                        _vm._v("Оформить подписку"),
                      ]),
                      _c("p", { staticClass: "subtitle text-muted" }, [
                        _vm._v(
                          "Доступ к данному веб-порталу возможен только при оплате подписки"
                        ),
                      ]),
                      _c(
                        "CRow",
                        { staticClass: "subactions" },
                        [
                          _c(
                            "CCol",
                            { attrs: { col: "12", xl: "6" } },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "cbtn shadow-none",
                                  attrs: { block: "", color: "dark" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.go(-1)
                                    },
                                  },
                                },
                                [_vm._v("Назад")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { col: "12", xl: "6" } },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "cbtn shadow-none",
                                  attrs: { block: "", color: "primary" },
                                },
                                [_vm._v("Оформить")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }